.custom-scroll {
    max-height: 200px;
    /* Set the desired static height */
    overflow-y: hidden;
    /* Hide the scrollbar */
}

.project-container {
    min-height: 550px;
    /* Set the desired height for each container */
}