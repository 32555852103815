.custom-heading {
    font-size: 28px;
}

.custom-header {
    background-color: #1E1E1E;
    color: white;
    font-weight: bold;
    text-align: center;
}

.custom-container {
    display: flex;
    background-color: #1E1E1E;
    max-height: 75em;
}

.custom-sidebar {
    color: lightgray;
    padding: 0px;
    overflow: auto;
    overflow-x: auto;
    width: 25em;
}

.custom-content {
    color: "";
    text-align: left;
    margin-left: 5px;
    overflow: auto;
    overflow-x: auto;
    width: 100%;
}

.custom-padding {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

.custom-list {
    margin: 0;
    font-weight: bold;
}

.custom-span {
    color: rgb(82, 82, 82);
    font-weight: bold;
}

.file-list-item {
    color: #828282;
}

.folder {
    padding-top: 7px;
    padding-bottom: 7px;
}

/* Add hover effect */
.file-list-item:hover {
    background-color: #333;
    /* Change background color on hover */
    cursor: pointer;
    /* Change cursor to pointer on hover */
}

.selected-file:hover,
.selected-file:focus {
    background-color: #333;
    /* Change background color on hover/focus */
    cursor: pointer;
    /* Change cursor to pointer on hover/focus */
}

.bold-text {
    font-weight: bold;
    color: white;
}




/* ScrollBar */
/* Customizing scrollbar */
::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: #1E1E1E;
    /* Background color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #555;
    /* Color of the scrollbar handle */
    border-radius: 5px;
    /* Border radius of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
    /* Color of the scrollbar handle on hover */
}